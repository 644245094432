import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, containerless } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MarkingColorService } from 'services/marking-color-service';
import { MarkingPlacementService } from 'services/marking-placement-service';
import { MarkingService } from 'services/marking-service';
import { MarkingTypeService } from 'services/marking-type-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class MarkingFormInlineEdit {
  @bindable private marking: Models.Marking;

  constructor(
    private markingService: MarkingService,
    private markingTypeService: MarkingTypeService,
    private markingColorService: MarkingColorService,
    private markingPlacementService: MarkingPlacementService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService
  ) {}

  private getMarkingColors() {
    return this.markingColorService.getAllCached()
        .then((res) => {
          return res.filter((x) => x.NavisionProductId || x.DeltaProductId || x.IsDeleted);
        });
  }

  private updateMarking() {
    this.marking.MarkingPlacement = null;
    this.marking.MarkingType = null;
    this.marking.MarkingColor = null;
    
    this.markingService
      .put(this.marking, this.marking.Id)
      .then((res) => {
        this.eventAggregator.publish('markingListReset', 1);
        this.eventAggregator.publish('markingFormEditClose', 1);
        this.toastService.showSuccess('marking.updated');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteMarking() {
    this.markingService
      .delete(this.marking.Id)
      .then((res) => {
        this.eventAggregator.publish('markingFormEditClose', 1);
        this.eventAggregator.publish('markingListReset', 1);
        this.toastService.showSuccess('marking.deleted');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancelEdit() {
    this.eventAggregator.publish('markingFormEditClose', 1);
    this.eventAggregator.publish('markingListReset', 1);
  }

}
