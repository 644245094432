import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ProductService } from 'services/product-service';

@autoinject
export class MarkingTypeForm {
  @bindable private markingtype: Models.MarkingType;

  private constructor(
    private errorService: ErrorService
  ) {}

  private bind() {    
  }
}
