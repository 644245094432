import { DeleteDialogService } from 'services/delete-dialog-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MarkingTypeService } from 'services/marking-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class MarkingTypeDetail {
  private markingtype: Models.MarkingType;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private markingTypeService: MarkingTypeService,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.markingTypeService
      .get(params.Id)
      .then( (res) => {
        this.markingtype = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateMarkingType() {
    this.markingTypeService
      .put(this.markingtype, this.markingtype.Id)
      .then((res) => {
        this.eventAggregator.publish('markingTypeListReset', 1);
        this.toastService.showSuccess('markingtype.updated');
        this.router.navigateToRoute('marking-type-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteMarkingType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.markingTypeService
          .delete(this.markingtype.Id)
          .then((res) => {
            this.eventAggregator.publish('markingTypeListReset', 1);
            this.toastService.showSuccess('markingtype.deleted');
            this.router.navigateToRoute('marking-type-list');
          })
          .catch((err) => this.errorService.handleError(err));
        }
      ); 
  }

  private gotoParentView() {
    this.eventAggregator.publish('markingTypeListReset', 1);
    this.router.navigateToRoute('marking-type-list');
  }
}
