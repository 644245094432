import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MarkingTypeService } from 'services/marking-type-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class MarkingTypeNew {
  private markingtype: Models.MarkingType = new Models.MarkingType();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private markingTypeService: MarkingTypeService
  ) {}

  private createMarkingType() {
    this.markingTypeService
      .post(this.markingtype)
      .then((res) => {
        this.eventAggregator.publish('markingTypeListReset', 1);
        this.toastService.showSuccess('markingtype.created');
        this.router.navigateToRoute('marking-type-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
