import { EventAggregator } from 'aurelia-event-aggregator';
import { bindable, bindingMode, containerless } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MarkingColorService } from 'services/marking-color-service';
import { MarkingPlacementService } from 'services/marking-placement-service';
import { MarkingService } from 'services/marking-service';
import { MarkingTypeService } from 'services/marking-type-service';
import { ToastService } from 'services/toast-service';

@containerless
@autoinject
export class MarkingFormInlineNew {
  @bindable private netId: number;
  private marking: Models.Marking = new Models.Marking();

  constructor(
    private markingService: MarkingService,
    private markingTypeService: MarkingTypeService,
    private markingColorService: MarkingColorService,
    private markingPlacementService: MarkingPlacementService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService
  ) {}

  private getMarkingColors() {
    return this.markingColorService.getAllCached()
        .then((res) => {
          return res.filter((x) => x.NavisionProductId || x.DeltaProductId || x.IsDeleted);
        });
  }

  private createMarking() {
    this.marking.NetId = this.netId;
    this.marking.MarkingPlacement = null;
    this.marking.MarkingType = null;
    this.marking.MarkingColor = null;
    
    this.markingService
      .post(this.marking)
      .then((res) => {
        this.eventAggregator.publish('markingListReset', 1);
        this.eventAggregator.publish('markingFormNewClose', 1);
        this.toastService.showSuccess('marking.created');
        this.marking = new Models.Marking();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancel() {
    this.eventAggregator.publish('markingFormNewClose', 1);
  }
}
