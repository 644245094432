import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ProductService } from 'services/product-service';

@autoinject
export class MarkingColorForm {
  @bindable private markingColor: Models.MarkingColor;

  private constructor(
    private errorService: ErrorService,
    private productService: ProductService
  ) {}

  private bind() {    
  }

  private setNavisionProductId(event) {
    this.markingColor.NavisionProductId = event.detail.value;
    this.setProductName(event.detail.value);
  }

  private setDeltaProductId(event) {
    this.markingColor.DeltaProductId = event.detail.value;
    this.setProductName(event.detail.value);
  }

  private setProductName(productId: string) {
    if (!this.markingColor.Name || this.markingColor.Name === '') {
      if (productId) {
        this.getProduct(productId).then((res) => {
          this.markingColor.Name = res.Name;
        });
      }
    }
  }

  private getProduct(id): Promise<any> {
    return new Promise((resolve, reject) => { this.productService.get(id)
      .then((res) => {        
        resolve(res);
      })
      .catch((err) => this.errorService.handleError(err));
    });
  }
}
